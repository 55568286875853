<!--
Load the video from the S2Links collection
Display an HLS player
If use has an account he can control the video, play, pause, seek, etc.
-->
<template>
  <div class="dark">
    <div v-if="errorMessage" class="vh-100 p-2">
      <b-alert show variant="danger">
        {{ errorMessage }}
      </b-alert>
    </div>
    <div v-else-if="link?.type == 'embed'" class="embed-responsive embed-responsive-16by9 vh-100">
      <iframe ref="embed" :src="link.videoUrl" frameborder="0" class="embed-responsive-item" allowfullscreen="true"
        mozallowfullscreen="true" webkitallowfullscreen="true" />
    </div>
    <div v-else class="vh-100 d-flex vh-main text-center">
      <div v-if="$store.account && false">
        Controls
        <input type="checkbox" v-model="controls" />
        <b-button @click="controlsPlay">Play</b-button>
        <b-button @click="controlsPause">Pause</b-button>
        <b-button @click="controlsClose">Close</b-button>
      </div>
      <div class="my-auto mx-auto" v-if="link">
        <slides-viewer v-if="link.type == 'slides' && slides" :slides="slides" @update="slidesUpdate" />
        <player ref="player" v-else-if="link.type == 'video' && videoUrl" :config="{ autoplay: true }" :url="videoUrl"
          @update="videoStatusUpdate" @ended="videoEnded" />
      </div>
    </div>
  </div>
</template>

<script>
import { getLog } from '@/services/log';
let log = getLog('Watch');
import { db } from '@/services/db';
import { randomString, getBrowser, removeUndefined } from '@/services/utils';
import { callable } from '@/services/functions';
import { appConfig } from '@/services/appconfig';

export default {
  components: {
    Player: () => import('@/views/tests/TestHLS.vue'),
    SlidesViewer: () => import('@/components/slidesViewer.vue'),
  },
  data() {
    return {
      controls: false,

      link: null,
      slides: null,
      videoUrl: null,
      visitId: null,
      maxSlide: 0,
      slideTime: {},

      errorMessage: null,

      lastUpdateCurrentTime: -10,
    };
  },
  async mounted() {
    this.visitId = randomString(32);
    let linkId = this.$route.params.id;
    let doc = await db.collection('S2Links').doc(linkId).get();
    if (doc.exists && !doc.data().expired) {
      log.log("Document data:", doc.data());
      await this.$bind('link', db.collection('S2Links').doc(linkId));
      if (this.link.type == 'slides') {
        let slides = []
        for (let i = 0; i < this.link.numSlides; i++) {
          slides.push(`${appConfig.contentServerHost}/s2/slides/${this.link.videoId}/slide-${i}.png`);
        }
        this.slides = slides;
        log.log("slides", this.slides);
      } else if (this.link.type == 'video') {
        this.videoUrl = this.link.videoUrl || this.link.video_url;
      }
      let data = removeUndefined({
        linkId,
        ownerId: this.link.ownerId,
        visitor: this.link.visitor,
        videoId: this.link.videoId,
        videoName: this.link.name,
        created: new Date(),
        completion: 0.0,
        authenticated: this.$store.account?.uid,
        device: {
          userAgent: navigator.userAgent,
          browser: getBrowser(),
        }
      });
      log.log("Visitor data", data);
      db.collection('S2Visits').doc(this.visitId).set(data, { merge: true });
      this.vidVisit();
    }
    else {
      log.log("This link as expired.");
      this.errorMessage = "This link has expired. Please contact the person who shared this link with you.";
    }
  },
  methods: {
    async vidVisit() {
      log.log("vidVisit", this.visitId);
      let res = await callable('vid-Visit')({
        visitId: this.visitId,
      });
      log.log("vidVisit res: ", res);
    },
    controlsPlay() {
      this.$refs.player.controlsPlay();
    },
    controlsPause() {
      this.$refs.player.controlsPause();
    },
    controlsClose() {
      
    },
    videoStatusUpdate(info) {
      // log.log("videoStatusUpdate", info);
      if (info.currentTime > this.lastUpdateCurrentTime + 5) {
        log.log("saving completion", info.currentTime);
        db.collection('S2Visits').doc(this.visitId).set({ completion: info.currentTime / info.totalTime }, { merge: true });
        this.lastUpdateCurrentTime = info.currentTime;
      }
    },
    videoEnded() {
      log.log("videoEnded");
      db.collection('S2Visits').doc(this.visitId).set({ completion: 1.0 }, { merge: true });
    },
    slidesUpdate(info) {
      log.log("slidesUpdate", info);
      let data = { time: {} };
      if (info.slide > this.maxSlide) {
        this.maxSlide = info.slide;
        data.completion = (info.slide + 1) / this.link.numSlides;
      }
      let index = `${info.previousSlide}`;
      let time = info.previousTime + (this.slideTime[index] || 0);
      data.time[index] = (this.slideTime[index] = time) / 1000;
      db.collection('S2Visits').doc(this.visitId)
        .set(data, { merge: true });
    },
  }
}
</script>

<style scoped>
.dark {
  background-color: #000;
  color: #fff;
}
</style>
